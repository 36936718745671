<template>
    <div class="func-ad-service">
        <div class="func-ad-service__image">
            <img src="~@/assets/images/ads/mobile-single-apple.svg" />
        </div>
        <div class="func-ad-service__content">
            <p>
                Pobierz naszą bezpłatną aplikację<br>
                na swój telefon i miej swoje<br>
                zdrowie zawsze pod ręką. 
            </p>
            <div class="buttons">
                <img @click="redirect('apple')" src="~@/assets/images/ads/mobile-app-store.svg" />
                <img @click="redirect('google')" src="~@/assets/images/ads/mobile-google-play.svg" />
            </div>
        </div>
    </div>
</template>

<script>

export default {
    
    name: 'ADService',
    methods: {

        redirect(app){

            let url;

            if (app === 'apple') url = 'https://apps.apple.com/pl/app/mydr/id1482090318?l=pl';
            if (app === 'google') url = 'https://play.google.com/store/apps/details?id=com.lekseek.mydr';

            window.open(url, '_blank');

        },

    },

};

</script>

<style lang="scss" src="./ADService.scss" />
